<template>
  <v-container>
    <v-row class="mb-5">
      <v-col>
        <h3>General Journal Entry - {{ journal_entry_number }}</h3>
      </v-col>
    </v-row>
    <!--FORM-->
    <v-form v-model="valid" ref="form">
      <v-row>
        <v-col md="4" sm="4">
          <!--Reference Number-->
          <v-text-field
            v-model="reference_number"
            dense
            outlined
            label="Reference #"
            required
            hint="Optional: Invoice, PO number, etc."
          >
          </v-text-field>
        </v-col>
        <v-col md="4" sm="4">
          <!--Supplier Name-->
          <v-autocomplete
            v-model="supplier"
            :items="suppliers"
            item-text="supplier_name"
            return-object
            dense
            outlined
            label="Supplier"
            required
          >
            <!--Add new supplier button-->
            <template v-slot:prepend-item>
              <v-list-item ripple>
                <v-dialog v-model="newSupplierDialog" width="850">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn text color="#3dcb9a" v-bind="attrs" v-on="on"
                      ><v-icon small left>mdi-plus</v-icon> Add New
                      Supplier</v-btn
                    >
                  </template>
                  <NewSupplierModal
                    @closeDialog="closeSupplierDialog($event)"
                    @passSupplierDetails="setSupplierDetails($event)"
                  />
                </v-dialog> </v-list-item
            ></template>
          </v-autocomplete>
        </v-col>
        <!--Date Picker-->
        <v-col cols="4" sm="4" md="4">
          <v-menu
            ref="menu"
            v-model="menu"
            :close-on-content-click="true"
            :return-value.sync="date"
            transition="scale-transition"
            min-width="290px"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                :value="journal_entry_date"
                label="Entry Date"
                append-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
                dense
                outlined
                clearable
                color="#3d2cdd"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="journal_entry_date"
              no-title
              scrollable
              color="#3d2cdd"
              show-adjacent-months
              :allowed-dates="
                (date) => date <= new Date().toISOString().substr(0, 10)
              "
            >
              <v-spacer></v-spacer>
              <v-btn text color="#ce2458" @click="menu = false"> Cancel </v-btn>
              <v-btn text color="#33cc99" @click="$refs.menu.save(date)">
                OK
              </v-btn>
            </v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <!--Entry Breakdown-->
      <v-row class="my-3">
        <v-col>
          <h3>Entry Breakdown</h3>
        </v-col>
      </v-row>
      <v-row>
        <v-col cols="5" sm="5" md="5">
          <h5>Ledger Account</h5>
        </v-col>
        <v-col cols="4" sm="4" md="4">
          <h5>Total Amount</h5>
        </v-col>
        <v-col cols="2" sm="2" md="2">
          <h5>VAT Rate</h5>
        </v-col>
      </v-row>
      <!--Line Items-->
      <v-form v-for="(item, index) in line_items" :key="index">
        <v-row>
          <v-col cols="5" sm="5" md="5">
            <!--Ledger Account-->
            <v-autocomplete
              v-model="item.ledger_account"
              :items="chart_of_accounts.filter((item) => !item.locked)"
              item-text="account_name"
              return-object
              dense
              outlined
              label="Ledger Account"
              :rules="[ledgerRules.required]"
              required
            >
            </v-autocomplete>
          </v-col>
          <v-col cols="4" sm="4" md="4">
            <!--Total Amount-->
            <v-text-field
              v-model.number="item.line_amount"
              type="number"
              dense
              outlined
              label="Total Amount"
              :rules="[amountRules.required, amountRules.min]"
              required
            >
            </v-text-field>
          </v-col>
          <v-col cols="2" sm="2" md="2">
            <v-select
              v-model="item.vat_rate"
              :items="[0, 15]"
              type="number"
              dense
              outlined
              label="VAT %"
              required
            >
            </v-select>
          </v-col>
          <!--Delete Button-->
          <v-col sm="1" md="1">
            <v-btn icon @click="removeItem(index)" :disabled="deleteDisable">
              <v-icon small>mdi-delete</v-icon>
            </v-btn>
          </v-col>
        </v-row>
        <!--Hidden Fields-->
        <v-row hidden>
          <v-col hidden>
            <!--VAT Amount-->
            <v-text-field
              hidden
              readonly
              v-bind:value="calculateVATAmount[index]"
            >
            </v-text-field>
            <!--Total Ex VAT-->
            <v-text-field
              hidden
              readonly
              v-bind:value="calculateTotalExVAT[index]"
            >
            </v-text-field>
          </v-col>
        </v-row>
      </v-form>
      <!--End of line items-->
      <!--Add more items to order-->
      <v-row class="mt-0">
        <v-col>
          <v-btn @click="addItems" text color="#33cc99" dark>
            <v-icon left>mdi-plus</v-icon>
            Add Line Item
          </v-btn>
        </v-col>
      </v-row>
      <!---->
    </v-form>
    <!--End of FORM-->
    <!--Divider-->
    <v-row class="my-3">
      <v-col>
        <v-divider></v-divider>
      </v-col>
    </v-row>
    <!--Buttons-->
    <v-row>
      <v-col>
        <AllocationBackBtn @passBack="$emit('passBack', $event)" />
        <!--Reconcile-->
        <v-btn
          color="primary"
          :disabled="
            calculateEntryTotal !== Math.abs(this.value.transaction_amount)
          "
          @click="createAndReconcileJournalEntry()"
          :loading="this.loading"
        >
          Reconcile Journal Entry
        </v-btn>
      </v-col>
    </v-row>
    <!--Validation Dialog-->
    <GeneralJournalEntryValidateDialog
      :general_journal_entry_validate_dialog="
        general_journal_entry_validate_dialog
      "
      :validation_message="validation_message"
      @deleteAccount="deleteBankAccount($event)"
      @closeDialog="closeDialog($event)"
    />
  </v-container>
</template>
<script>
import db from "../../../../../components/firebaseInit";
import mixin_CompanyProfile from "../../../../../globalActions/mixin_CompanyProfile";
import { getSuppliers } from "../../../data/external_bank_statements";
import {
  getJournalEntryCount,
  getUser,
  createAndReconcileJournalEntry,
} from "../../../../Accounting/data/external_journal_entries";
export default {
  name: "GeneralJournalEntriesForm",
  props: ["value"],
  mixins: [mixin_CompanyProfile],
  components: {
    GeneralJournalEntryValidateDialog: () =>
      import("./GeneralJournalEntryValidateDialog.vue"),
    NewSupplierModal: () =>
      import("../../../../Suppliers/components/NewSupplierModal.vue"),
    AllocationBackBtn: () => import("../AllocationBackBtn.vue"),
  },
  data() {
    return {
      valid: false,
      loading: false,
      menu: null,
      date: null,
      journal_entry_id: null,
      journal_entry_number: null,
      reference_number: null,
      supplier: { supplier_name: null },
      journal_entry_date: this.value.transaction_date,
      deleteDisable: true,
      line_items: [
        {
          ledger_account: null,
          line_amount: Math.abs(this.value.transaction_amount),
          vat_rate: 0,
          vat_amount: null,
          entry_amount: null,
        },
      ],

      journal_entry_created_by_id: null,
      journal_entry_created_by_name: null,
      chart_of_accounts: [],
      suppliers: [],
      newSupplierDialog: false,
      general_journal_entry_validate_dialog: false,
      validation_message: null,
      // Validation Rules
      amountRules: {
        required: (v) => !!v || "An amount is required",
        min: (v) => v > 0 || "Amount must be greater than 0",
      },
      ledgerRules: {
        required: (v) => !!v || "You must select a ledger",
      },
    };
  },
  created() {
    this.getChartOfAccounts();
    this.getSuppliers();
    this.getJournalEntryCount();
    this.getUser();
  },
  computed: {
    determineVATRate() {
      return this.company_is_vattable ? 15 : 0;
    },
    calculateVATAmount() {
      return this.line_items.map(
        (item) =>
          (item.vat_amount =
            (item.line_amount * item.vat_rate) / (100 + item.vat_rate))
      );
    },
    calculateTotalExVAT() {
      return this.line_items.map(
        (item) => (item.entry_amount = item.line_amount - item.vat_amount)
      );
    },
    calculateEntryTotal() {
      //  Total of all line items
      return this.line_items.reduce((total, item) => {
        return total + item.line_amount;
      }, 0);
    },
  },
  methods: {
    getSuppliers,
    getJournalEntryCount,
    getUser,
    /*
        The ledger dropdown must be populated by "Accounting Totals"
        sub-collection docs. If we use the main "Chart Of Accounts" collection
        the user can select an account before the Cloud Function has triggered
        to add it to the sub-collection.
    */
    getChartOfAccounts() {
      const accountsRef = db
        .collection("accounting_totals")
        .doc(this.value.transaction_date.slice(0, 7))
        .collection("ledger_accounts")
        .orderBy("account_name");
      accountsRef.onSnapshot((querySnapshot) => {
        this.chart_of_accounts = [];
        querySnapshot.forEach((doc) => {
          const data = {
            account_name: doc.data().account_name,
            account_id: doc.data().account_id,
          };
          this.chart_of_accounts.push(data);
        });
      });
    },
    passStepperChangePrevious() {
      this.$emit("triggerStepperChangePrevious");
    },
    //Adds a new empty line item to the order
    addItems() {
      this.line_items.push({
        ledger_account: null,
        line_amount: 0,
        vat_rate: this.company_is_vattable ? 15 : 0,
      });
      this.deleteDisable = false;
    },
    //Deletes a specific line item from the order
    removeItem(index) {
      this.line_items.splice(index, 1);
      if (this.line_items.length === 1) {
        this.deleteDisable = true;
      }
    },
    validate() {
      this.$refs.form.validate();
    },
    openViewEntryModal() {},
    //_____________________________________________________________
    createAndReconcileJournalEntry,
    //  Close validation dialog box
    closeDialog(general_journal_entry_validate_dialog) {
      this.general_journal_entry_validate_dialog =
        general_journal_entry_validate_dialog;
    },
    closeSupplierDialog(value) {
      this.newSupplierDialog = value;
    },
    setSupplierDetails(details) {
      this.supplier = details;
    },
  },
};
</script>
